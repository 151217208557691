import api from ".";

export const getForceUpdateMinVersion = () => {
  return api.get("/configuration/get-force-update-version");
};

export const updateForceUpdateMinVersion = (version) => {
  return api.post("/configuration/set-force-update-version", {
    version,
  });
};
