import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider, useSelector } from "react-redux";

import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-notifications/lib/notifications.css";
import "react-quill/dist/quill.snow.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ability from "./ability";

import AdminLayout from "layouts/Admin.js";
import Login from "./pages/Login";

import { NotificationContainer } from "react-notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AbilityContext } from "components/can";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

const Private = ({ children }) => {
  const user = useSelector((state) => state.auth.user);

  if (user) {
    return <>{children}</>;
  }

  return <Redirect to="/" />;
};

const Guest = ({ children }) => {
  const user = useSelector((state) => state.auth.user);

  if (user) {
    return <Redirect to="/admin/dashboard" />;
  }

  return <>{children}</>;
};

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Guest>
                <Login />
              </Guest>
            )}
          />
          <Route
            path="/admin"
            render={(props) => (
              <Private>
                <AdminLayout {...props} />
              </Private>
            )}
          />
        </Switch>
      </BrowserRouter>

      <NotificationContainer />
    </Provider>
  </QueryClientProvider>
);
