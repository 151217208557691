import { updateTemplate } from "api/templates";
import { getTemplates } from "api/templates";
import { getForceUpdateMinVersion, updateForceUpdateMinVersion } from "api/configuration";
import { Can } from "components/can";
import React, { useEffect, useState } from "react";

// react-bootstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Alert,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";

function Configuration() {
  const [templates, setTemplates] = useState([]);
  const [saving, setSaving] = useState();

  const [version, setVersion] = useState("");

  const updateForceUpdateVersion = async (e) => {
    e.preventDefault();
    try {
      const response = await updateForceUpdateMinVersion(version);
      loadForceUpdateVersion();
      NotificationManager.success(response.data.message);
    } catch (error) {
      console.error("Error saving version:", error);
      NotificationManager.error("Error saving version. Please try again.");
    }
  };

  const loadForceUpdateVersion = async () => {
    try {
      const response = await getForceUpdateMinVersion();
      setVersion(response.data.version);
    } catch (error) {
      console.error("Error loading force update version:", error);
    }
  }

  const loadTemplates = async () => {
    try {
      const { data } = await getTemplates();

      setTemplates(data.templates);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadForceUpdateVersion();
    loadTemplates();
  }, []);

  const handleUpdate = async (name) => {
    try {
      setSaving(name);
      await updateTemplate(templates.find((t) => t?.name === name));

      NotificationManager.success("Configuration successfully updated.");
      setSaving(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid>
        {templates.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300,
            }}
          >
            <Spinner animation="grow" variant="dark" />
          </div>
        )}

        <Row>
          <Col xs={12}>
            <h3>Force App Update</h3>
            <Form onSubmit={updateForceUpdateVersion}>
              <Form.Group controlId="versionInput">
                <Form.Label>Minimum app version</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter version (e.g., 1.2.3)"
                  value={version}
                  onChange={(e) => setVersion(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-2">
                Save
              </Button>
            </Form>
          </Col>
        </Row>

        <Can I="read" this="Configuration">
          <Row>
            {templates.map((t, idx) => (
              <Col key={idx} xs={12}>
                <h3>{String(t?.name).replaceAll("_", " ")}</h3>

                {t?.title !== undefined && (
                  <input
                    className="mb-3 p-2"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Title"
                    value={t?.title}
                    onChange={(e) =>
                      setTemplates((prev) =>
                        prev.map((i) => {
                          if (i.name === t.name) {
                            i.title = e.target.value;
                          }
                          return i;
                        })
                      )
                    }
                  />
                )}

                <br />
                {t?.content !== undefined && (
                  <textarea
                    style={{
                      width: "100%",
                    }}
                    className=" p-2"
                    rows={10}
                    value={t?.content}
                    onChange={(e) =>
                      setTemplates((prev) =>
                        prev.map((i) => {
                          if (i.name === t.name) {
                            i.content = e.target.value;
                          }
                          return i;
                        })
                      )
                    }
                  ></textarea>
                )}

                <Can I="update" this="Configuration">
                  <div className="text-center">
                    <Button
                      className="mt-3"
                      onClick={() => handleUpdate(t?.name)}
                    >
                      {saving === t?.name && (
                        <Spinner animation="grow" size="sm" />
                      )}{" "}
                      Save
                    </Button>
                  </div>
                </Can>
              </Col>
            ))}
          </Row>
        </Can>

        <Can not I="read" this="Configuration">
          <Alert variant="danger">
            You do not have proper permission to access this information.
          </Alert>
        </Can>
      </Container>
    </>
  );
}

export default Configuration;
